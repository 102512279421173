
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import "nprogress/nprogress.css";
import "../../styles/global.css";
import { SWRConfig } from "swr";
import { Component as ReactComponent } from "react";
import Router from "next/router";
import NProgress from "nprogress";
import Head from "next/head";
import AuthTokenStore from "@/common/store/AuthTokenStore";
import request from "@/utils/request";
import { createSocketInstance, SocketProvider } from "@/common/context/SocketContext";
import ToastNotification from "@/components/common/ToastNotification";
import { setCache } from "@/utils/cache";
import CookieConsentStore from "@/common/store/CookieConsentStore";
import DashboardMenuStore from "@/common/store/DashboardMenuStore";
import BannerImageStore from "@/common/store/BannerImageStore";
import isBrowser from "@/utils/isBrowser";
import CacheKeys from "@/common/models/CacheKeys";

const fetcher = (url, options) => request.get(url, options).then((res) => res.data);

class App extends ReactComponent {
  constructor(props) {
    super(props);
    if (isBrowser()) {
      AuthTokenStore.initialize();
      BannerImageStore.init();
      setCache(CookieConsentStore.KEY, CookieConsentStore.get());
      setCache(DashboardMenuStore.KEY, DashboardMenuStore.get());
      setCache(CacheKeys.RAND_ID, Math.floor(Math.random() * 4) + 1);

      this.socket = createSocketInstance();
    }
  }

  componentDidMount() {
    window.OneSignal = window.OneSignal || [];
    window.OneSignal.push(() => {
      window.OneSignal.init({
        appId: process.env.NEXT_PUBLIC_ONESIGNAL_APP_ID,
        notifyButton: {
          enable: false,
        },

        allowLocalhostAsSecureOrigin: true,
      });
    });

    NProgress.configure({ showSpinner: false });
    Router.events.on("routeChangeStart", (_, { shallow }) => {
      if (!shallow) NProgress.start();
    });
    Router.events.on("routeChangeComplete", (_, { shallow }) => {
      if (!shallow) NProgress.done();
    });
    Router.events.on("routeChangeError", (_, { shallow }) => {
      if (!shallow) NProgress.done();
    });

    if (!AuthTokenStore.has()) {
      setTimeout(() => {
        request.get("/notify").then(({ data }) => {
          data.forEach((notification) => {
            setCache(CacheKeys.TOAST, {
              message: notification.message.message,
              event: "push",
            });
          });
        });
      }, 20 * 1000);
    }
  }

  componentWillUnmount() {
    window.OneSignal = undefined;
  }

  render() {
    const { Component, pageProps } = this.props;

    return (
      <SWRConfig value={{ fetcher, revalidateOnFocus: false }}>
        <SocketProvider socket={this.socket}>
          <ToastNotification />
          <Head>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
          </Head>
          <Component {...pageProps} />
        </SocketProvider>
      </SWRConfig>
    );
  }
}

const __Page_Next_Translate__ = App;


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  
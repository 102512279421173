import { useCallback } from "react";
import ReactHowler from "react-howler";
import NotificationItem from "@/components/common/NotificationItem";
import IconClose from "@/common/icons/close.svg";
import Sounds from "@/common/models/Sounds";

const ToastNotificationItem = ({ notification, dismissNotification }) => {
  const dismiss = useCallback(() => dismissNotification(notification.id), [dismissNotification, notification.id]);

  return (
    <div className="flex py-4 px-4 w-full bg-white rounded-2xl shadow-xl transition-all duration-300 animate-fade-in pointer-events-auto">
      <NotificationItem notification={notification.message} type={notification.type} handleClick={dismiss} />
      <button
        type="button"
        className="flex-shrink-0 ml-4 w-6 h-6 bg-grey-400 rounded-full opacity-50 hover:opacity-100 cursor-pointer flex-center"
        onClick={dismiss}
      >
        <IconClose className="w-3 h-3 text-grey-800" />
      </button>
      {Sounds[notification.event] && <ReactHowler src={Sounds[notification.event]} />}
    </div>
  );
};

export default ToastNotificationItem;

class UserStateModel {
  constructor(value, languageKey, color = "text-grey-800") {
    this.value = value;
    this.languageKey = languageKey;
    this.color = color;
  }

  get textColor() {
    return this.color;
  }

  equals(state) {
    return this.value === state;
  }
}

const UserState = {
  ONLINE: new UserStateModel(1, "common:state.online", "text-green"),
  OFFLINE: new UserStateModel(2, "common:state.offline"),
  BUSY: new UserStateModel(3, "common:state.busy", "text-yellow"),
  ABSENT: new UserStateModel(4, "common:state.absent"),
  CALL: new UserStateModel(5, "common:state.call"),
  PAUSE: new UserStateModel(6, "common:state.pause", "text-yellow"),
};

Object.values(UserState).forEach((val) => {
  UserState[val.value] = val;
});

export default UserState;

import BaseStore from "./BaseStore";
import { setCache } from "@/utils/cache";

export default class DashboardMenuStore extends BaseStore {
  static KEY = "dashboard-menu";

  static CONSULTANT = "consultant";

  static CUSTOMER = "customer";

  static set(value, applyCache = true) {
    if (applyCache) {
      setCache(DashboardMenuStore.KEY, value);
    }

    super.set(value, null);
  }

  static valid(value, isConsultant, isCustomer) {
    if (value === this.CONSULTANT && !isConsultant) {
      this.set(this.CUSTOMER);
      return;
    }

    if (value === this.CUSTOMER && !isCustomer) {
      this.set(this.CONSULTANT);
    }
  }

  static defaultForUser(isConsultant) {
    this.set(isConsultant ? this.CONSULTANT : this.CUSTOMER);
  }
}

import BaseStore from "./BaseStore";
import { setCache } from "@/utils/cache";

export default class BannerImageStore extends BaseStore {
  static KEY = "banner-image";

  static init() {
    const bannerImage = this.get();
    if (bannerImage !== null) {
      setCache(this.KEY, bannerImage);
    } else {
      this.set();
    }
  }

  static set(ctx = null) {
    const value = Math.floor(Math.random() * 3) + 1;
    setCache(this.KEY, value);
    super.set(value, ctx, null);
  }
}

import CacheKeys from "@/common/models/CacheKeys";
import request from "@/utils/request";
import CookieConsentStore from "@/common/store/CookieConsentStore";

const cache = new Map([
  [CacheKeys.CHAT_ACTIVE, false],
  [CookieConsentStore.KEY, null],
  [CacheKeys.RAND_ID, null],
]);
const subscribers = new Map();

function getSubscribers(key) {
  if (!subscribers.has(key)) subscribers.set(key, []);
  return subscribers.get(key);
}

export function subscribe(key, callback) {
  getSubscribers(key).push(callback);
}

export function unsubscribe(key, callback) {
  const subs = getSubscribers(key);
  const index = subs.indexOf(callback);
  if (index >= 0) {
    subs.splice(index, 1);
  }
}

export function getCache(key) {
  return cache.get(key);
}

export function setCache(key, value) {
  if (cache.get(key) !== value) {
    if (key === CacheKeys.CHAT_ACTIVE && !value) {
      request.get("/connection/stop").then();
    }

    cache.set(key, value);
    getSubscribers(key).forEach((cb) => cb());
  }
}

import Image from "next/image";

const Avatar = ({ image, author, className }) =>
  typeof image === "string" ? (
    <div className={`relative rounded-full overflow-hidden ${className}`}>
      <Image src={image} layout="fill" objectFit="cover" alt={author} />
    </div>
  ) : (
    <div className={`bg-blue flex-center text-white rounded-full ${className}`} title={author}>
      {author.substring(0, 2).toUpperCase()}
    </div>
  );

export default Avatar;

import { useCallback, useEffect, useState } from "react";
import useSocket from "@/common/hook/useSocket";
import ToastNotificationItem from "./ToastNotificationItem";
import { getCache, subscribe, unsubscribe } from "@/utils/cache";
import CacheKeys from "@/common/models/CacheKeys";

const ToastNotification = () => {
  const [notifications, setNotifications] = useState([]);
  const socket = useSocket();

  const dismissNotification = useCallback((id) => setNotifications((prev) => prev.filter((t) => t.id !== id)), []);

  const handleToastMessage = useCallback(
    (data) => {
      const id = Date.now();
      setNotifications((prev) => [...prev, { id, ...data }]);
      setTimeout(() => dismissNotification(id), 5000);
    },
    [dismissNotification]
  );

  useEffect(() => {
    socket.on("toast", handleToastMessage);
    return () => socket.off("toast");
  }, [dismissNotification, handleToastMessage, socket]);

  useEffect(() => {
    const onToastMessage = () => {
      const toast = getCache(CacheKeys.TOAST);
      if (toast) {
        handleToastMessage(toast);
      }
    };
    subscribe(CacheKeys.TOAST, onToastMessage);

    return () => unsubscribe(CacheKeys.TOAST, onToastMessage);
  }, [handleToastMessage]);

  return (
    <div className="overflow-hidden fixed bottom-0 left-0 z-40 md:z-50 py-8 px-4 w-full md:max-w-md max-h-screen pointer-events-none">
      <div className="flex flex-col space-y-4 pointer-events-none">
        {notifications.map((notification) => (
          <ToastNotificationItem
            key={notification.id}
            notification={notification}
            dismissNotification={dismissNotification}
          />
        ))}
      </div>
    </div>
  );
};

export default ToastNotification;
